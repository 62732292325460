<template>
  <div class="AgreementContainer">
    <table class="AgreementTable">
      <colgroup>
        <col v-if="!hideSubject" width="20%" />
        <col width="20%" />
        <col width="45%" />
        <col width="75px" />
      </colgroup>
      <tr>
        <th :hidden="hideSubject">{{ subjectHeader }}</th>
        <th>{{ proposalHeader }}</th>
        <th>{{ remarksHeader }}</th>
        <th :hidden="hideStamp">{{ stampHeader }}</th>
      </tr>
      <tr>
        <td rowspan="3" :hidden="hideSubject">
          {{ loandescription }}
        </td>
        <td :class="{ Accepted: isAccepted, Neutral: !isAccepted }">
          <button
            type="button"
            :disabled="isAcceptedSaved || disableSave"
            class="choicebutton Accept"
            @click="acceptClicked"
          >
            {{ agreeButttonCaption }}
          </button>
        </td>
        <td>
          <textarea
            id="acceptReason"
            v-model="agreedRemark"
            class="remarkinput"
            :placeholder="remarkPlaceholder"
            maxlength="250"
            :readonly="textReadOnly || isAcceptedSaved"
          />
        </td>
        <td :hidden="hideStamp">
          {{ firstStamp }}
        </td>
      </tr>
      <tr>
        <td :class="{ Withdrawn: isWithdrawn, Neutral: !isWithdrawn }">
          <button
            type="button"
            :disabled="isWithdrawnSaved || disableSave"
            class="choicebutton Withdraw"
            @click="withdrawClicked"
          >
            {{ withdrawButttonCaption }}
          </button>
        </td>
        <td>
          <textarea
            id="withdrawReason"
            v-model="withdrawnRemark"
            class="remarkinput"
            :placeholder="remarkPlaceholder"
            maxlength="250"
            :readonly="textReadOnly || isWithdrawnSaved"
          />
        </td>
        <td :hidden="hideStamp">
          {{ secondStamp }}
        </td>
      </tr>
      <tr>
        <td :class="{ Denied: isDenied, Neutral: !isDenied }">
          <button type="button" :disabled="isDeniedSaved || disableSave" class="choicebutton Deny" @click="denyClicked">
            {{ denyButttonCaption }}
          </button>
        </td>
        <td>
          <textarea
            id="denyReason"
            v-model="deniedRemark"
            class="remarkinput"
            :placeholder="remarkPlaceholder"
            maxlength="250"
            :readonly="textReadOnly || isDeniedSaved"
          />
        </td>
        <td :hidden="hideStamp">
          {{ thirdStamp }}
        </td>
      </tr>
    </table>

    <button
      class="blue-btn confirmbutton"
      :disabled="cannotSubmit"
      :hidden="disableSave"
      @click="submitAgreementRequest"
      v-text="saveAndClose"
    />
    <br />
  </div>
</template>

<script>
import { tryGetValue } from '@/utils';
import { closeCurrentTag } from '@/utils/tags';

export default {
  props: {
    loanInfo: {
      type: Object,
      default: () => ({})
    },
    loanDescription: {
      type: String,
      default: 'lening'
    },
    loanId: Number,
    agreementBy: Number,
    displaySubject: {
      type: Boolean,
      default: false
    },
    allowSave: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loanid: this.loanInfo.loanid,
      Onderwerp: {
        type: String,
        default: 'Onderwerp'
      },
      Voorstel: {
        type: String,
        default: 'Voorstel'
      },
      Opmerking: {
        type: String,
        default: 'Opmerking'
      },
      remarkPlaceholder: 'Vrij invoerveld',
      saveAndClose: 'Opslaan & indienen',
      subjectVisible: true,
      isAccepted: false,
      isWithdrawn: false,
      isDenied: false,
      isAcceptedSaved: false,
      isWithdrawnSaved: false,
      isDeniedSaved: false,
      allowSubmit: false,
      agreementStatus: 0,
      agreementRemark: '',
      firstRemark: '',
      secondRemark: '',
      thirdRemark: '',
      firstStamp: '--',
      secondStamp: '--',
      thirdStamp: '--',
      readonly: ''
    };
  },
  computed: {
    loandescription: {
      get() {
        return this.loanDescription;
      }
    },
    subjectHeader: {
      get() {
        return 'Onderwerp';
      }
    },
    stampHeader: {
      get() {
        return 'Stempel';
      }
    },
    proposalHeader: {
      get() {
        switch (this.agreementBy) {
          case 0:
            return 'Voorstel consultant FH';
          case 1:
            return 'Actie manager PV PostNL';
          case 2:
            return 'Actie bestuur';
          case 3:
            return 'Besluit manager PV PostNL';
          default:
            return 'Voorstel';
        }
      }
    },
    remarksHeader: {
      get() {
        switch (this.agreementBy) {
          case 0:
            return 'Opmerking consultant FH';
          case 1:
            return 'Opmerking manager PV PostNL';
          case 2:
            return 'Opmerking bestuur';
          case 3:
            return 'Opmerking manager PV PostNL';
          default:
            return 'Opmerkingen';
        }
      }
    },
    returnTo: {
      get() {
        switch (this.agreementBy) {
          case 0:
            return 'home';
          case 1:
            return 'manager/dossiersManager';
          case 2:
            return 'board/dossiersBoard';
          case 3:
            return 'boardTKdossiersBoardTK';
          default:
            return '';
        }
      }
    },
    agreeButttonCaption: {
      get() {
        return 'Goedkeuren';
      }
    },
    withdrawButttonCaption: {
      get() {
        return 'Intrekken';
      }
    },
    denyButttonCaption: {
      get() {
        return 'Afkeuren';
      }
    },
    hideSubject: {
      get() {
        return !this.displaySubject;
      }
    },
    hideStamp: {
      get() {
        if (this.agreementBy === -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    agreedRemark: {
      get: function() {
        return this.firstRemark;
      },
      set: function(value) {
        this.firstRemark = value;
      }
    },
    withdrawnRemark: {
      get: function() {
        return this.secondRemark;
      },
      set: function(value) {
        this.secondRemark = value;
      }
    },
    deniedRemark: {
      get: function() {
        return this.thirdRemark;
      },
      set: function(value) {
        this.thirdRemark = value;
      }
    },
    disableSave: {
      get() {
        return !this.allowSave;
      }
    },
    textReadOnly: {
      get() {
        return !this.allowSave;
      }
    },
    cannotSubmit: {
      get() {
        return !this.allowSubmit;
      }
    }
  },
  created() {
    const params = {};
    params.query = { loanid: this.$props.loanId, agreementby: this.$props.agreementBy };
    this.$store.dispatch('loanagreement/getItems', params).then(data => {
      if (!data) {
        console.log('No agreements returned for loan %d and by %d', this.$props.loanId, this.$props.agreementBy);
      }
      const agreements = tryGetValue(data, 'items') || [];
      if (agreements.length > 0) {
        const element = agreements[0];
        if (element.status != null) {
          switch (element.status.code) {
            case 'GOED':
              this.isAccepted = true;
              this.isAcceptedSaved = true;
              this.firstRemark = element.remarks;
              this.firstStamp = element.stamp;
              break;
            case 'GOEM':
              this.isAccepted = true;
              this.isAcceptedSaved = true;
              this.firstRemark = element.remarks;
              this.firstStamp = element.stamp;
              break;
            case 'ING':
              this.isWithdrawn = true;
              this.isWithdrawnSaved = true;
              this.secondRemark = element.remarks;
              this.secondStamp = element.stamp;
              break;
            case 'AFWB':
              this.isDenied = true;
              this.isDeniedSaved = true;
              this.thirdRemark = element.remarks;
              this.thirdStamp = element.stamp;
              break;
          }
        }
      }
    });
  },
  methods: {
    acceptClicked() {
      if (this.isAccepted) {
        this.allowSubmit = false;
        this.isAccepted = false;
        this.agreementStatus = 0;
        this.agreementRemark = '';
      } else {
        this.allowSubmit = true;
        this.isAccepted = true;
        this.agreementStatus = 1;
        if (!this.isDeniedSaved) {
          this.isDenied = false;
        }
        if (!this.isWithdrawnSaved) {
          this.isWithdrawn = false;
        }
      }
    },
    withdrawClicked() {
      if (this.isWithdrawn) {
        this.allowSubmit = false;
        this.isWithdrawn = false;
        this.agreementStatus = 0;
        this.agreementRemark = '';
      } else {
        this.allowSubmit = true;
        this.isWithdrawn = true;
        this.agreementStatus = 2;
        if (!this.isAcceptedSaved) {
          this.isAccepted = false;
        }
        if (!this.isDeniedSaved) {
          this.isDenied = false;
        }
      }
    },
    denyClicked() {
      this.allowSubmit = false;
      if (this.isDenied) {
        this.isDenied = false;
        this.agreementStatus = 0;
        this.agreementRemark = '';
      } else {
        this.allowSubmit = true;
        this.isDenied = true;
        this.agreementStatus = 3;
        if (!this.isAcceptedSaved) {
          this.isAccepted = false;
        }
        if (!this.isWithdrawnSaved) {
          this.isWithdrawn = false;
        }
      }
    },
    async submitAgreementRequest() {
      switch (this.agreementStatus) {
        case 1:
          this.agreementRemark = this.firstRemark;
          break;
        case 2:
          this.agreementRemark = this.secondRemark;
          break;
        case 3:
          this.agreementRemark = this.thirdRemark;
          break;
        default:
          this.agreementRemark = '';
      }
      const request = {
        LoanId: this.loanInfo.id,
        AgreementBy: this.agreementBy,
        agreementStatus: this.agreementStatus,
        remarks: this.agreementRemark
      };
      try {
        await this.$store.dispatch('loanagreement/addloanagreement', request);
      } finally {
        console.log('Agreement request saved');
        closeCurrentTag(this, false, { name: this.returnTo });
      }
    }
  }
};
</script>
<style src="@/styles/components/agreements.scss" lang="scss"></style>
