<template>
  <div>
    <dossier-info-header-card class="no-float margin" :dossier-info="headerCardInfo"> </dossier-info-header-card>
    <crud-editor
      ref="editor"
      :is-edit="true"
      :definition="definition"
      :fetch-by-id="fetchById"
      :disabled="true"
      :save="saveData"
      :return-to="returnToPage"
      :item-type="entity"
      :page-loading="loading"
      :get-dependencies="getDependencies"
    />
    <div class="agreementform">
      <div class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="0"
          :display-subject="true"
          :allow-save="false"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="1"
          :display-subject="false"
          :allow-save="allowSaveManager"
          :loading="loading"
        ></AgreementTable>
      </div>
      <div v-if="showBoardAgreement" class="agreement">
        <AgreementTable
          v-if="loanId"
          :loan-info="loanInfo"
          :loan-id="loanId"
          :loan-description="loanDescription"
          :agreement-by="2"
          :display-subject="false"
          :allow-save="allowSaveBoard"
          :loading="loading"
        ></AgreementTable>
      </div>
    </div>
    <div class="app-container">
      <label title="Historie">Activiteiten logboek</label>
      <el-table ref="agreementlog" :data="agreements" stripe>
        <el-table-column property="agreementByText" :label="$t('common.role')" width="200" />
        <el-table-column property="userName" :label="$t('common.username')" width="200" />
        <el-table-column property="statusText" :label="$t('loan.agreementAction')" width="300" />
        <el-table-column property="date" :label="$t('common.date')" width="125" />
        <el-table-column property="time" :label="$t('common.time')" width="125" />
        <el-table-column property="remarks" :label="$t('common.remarks')" />
      </el-table>
    </div>
  </div>
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
import { tryGetValue } from '@/utils';
import formMixin from './form-mixin';
import DossierInfoHeaderCard from '@/pages/dossiers/DossierInfoHeaderCard';
import AgreementTable from '@/components/DSE/AgreementTable';
export default {
  components: {
    CrudEditor,
    DossierInfoHeaderCard,
    AgreementTable
  },
  mixins: [formMixin],
  props: {
    allowsave: Boolean,
    returnToPage: String
  },
  data() {
    return {
      headerCardInfo: {},
      loanInfo: {},
      loanId: 0,
      loanAgreements: [],
      description: '',
      allowSaveManager: false,
      allowSaveBoard: false,
      agreementby: 0,
      agreementfrom: 0,
      returntopage: '',
      showBoardAgreement: false
    };
  },
  computed: {
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    },
    agreementType() {
      return this.agreementtype;
    },
    loanDescription() {
      return this.description;
    },
    saveAllowed() {
      return this.allowSave;
    },
    agreements() {
      return this.loanAgreements;
    }
  },
  methods: {
    async fetchById(id) {
      const byNone = -1;
      const byConsultant = 0;
      const byManager = 1;
      const byBoard = 2;
      const loan = await this.$store.dispatch(this.entityStore + '/getItem', {
        selector: { id }
      });
      const dossier = await this.$store.dispatch('dossier/getItem', {
        selector: { id: loan.dossierID }
      });
      const params = {};
      params.query = { loanid: id };
      await this.$store.dispatch('loanagreement/getItems', params).then(data => {
        if (!data) {
          console.log('Geen loanagreements gevonden');
        } else {
          console.log('Loanagreements gevonden');
        }
        this.loanAgreements = tryGetValue(data, 'items') || [];
      });
      const status = await this.$store.dispatch('status/getItem', { selector: { id: loan.statusID } });
      this.returntopage = this.returnTo;
      this.statusCode = status.code;
      this.loanId = +id;
      this.headerCardInfo = dossier;
      this.loanInfo = loan;
      this.description = loan.displayName;
      const manageragreements = this.loanAgreements.filter(a => a.agreementBy === byManager).length > 0;
      if (this.statusCode === 'TKB') {
        console.log('Lening ter kennisneming');
        this.agreementby = byNone;
        this.agreementform = byConsultant;
        this.allowSaveBoard = false;
        this.allowSaveManager = false;
      } else {
        this.allowSave = true;
        if (loan.lastAgreementStatusCode === 'AFWB') {
          if (!manageragreements & !loan.needsBoardAgreement) {
            console.log('Afwijzing ter beoordeling manager');
            this.allowSaveManager = true;
            this.agreementby = byManager;
            this.agreementfrom = byConsultant;
          } else {
            console.log('Afwijzing ter beoordeling bestuur');
            this.allowSaveBoard = true;
            this.agreementby = byBoard;
            this.agreementfrom = loan.lastAgreementBy;
            this.showBoardAgreement = true;
          }
        } else if (loan.needsBoardAgreement) {
          console.log('Lening ter beoordeling bestuur');
          this.allowSaveBoard = true;
          this.agreementby = byBoard;
          this.agreementfrom = loan.lastAgreementBy;
          this.showBoardAgreement = true;
        } else {
          console.log('Lening ter beoordeling manager');
          this.allowSaveManager = true;
          this.agreementby = byManager;
          this.agreementfrom = byConsultant;
        }
      }
      return this.createFormDataFromItem(this, loan);
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    }
  }
};
</script>

<style scoped>
.no-float {
  float: none;
}

.margin {
  margin: 10px;
}
</style>
<style>
.remark {
  margin: 5px;
  margin-left: 20px;
  height: 4em;
  width: 90%;
}

.el-switch__label.is-active {
  color: blue;
}
h4 {
  margin: 5px;
}
.el-input.is-disabled {
  color: black;
}
.el-input.is-disabled .el-input__inner {
  color: black;
}
.el-textarea.is-disabled .el-textarea__inner {
  color: black;
}
</style>
